import { AvatarButtonGroupProps } from 'src/design-system'
import { User } from 'store/modules/users'

type AvatarPropsOptions = {
  href?: boolean
  stateTooltip?: boolean
}

export const avatarProps = (user: User) => {
  return {
    alt: `Avatar for ${user.fullName}`,
    fallbackText: user.initials,
    isInactive: user.state !== 'active',
    src: user.avatarUrl,
    tooltipContent: user.fullName,
  }
}

export const avatarGroupProps = (
  users: User[],
  options?: AvatarPropsOptions
): Exclude<AvatarButtonGroupProps['avatars'], undefined> => {
  return users.reduce((arr, user) => {
    if (arr.find((avatar) => avatar.identifier === user.slug)) return arr
    const baseProps = {
      ...avatarProps(user),
      href: options?.href ? `/users/${user.slug}` : undefined,
      identifier: user.slug,
    }

    if (options?.stateTooltip) {
      return [
        ...arr,
        {
          ...baseProps,
          tooltipContent: stateTooltipContent(user.fullName, user.state),
        },
      ]
    } else {
      return [...arr, baseProps]
    }
  }, [] as Exclude<AvatarButtonGroupProps['avatars'], undefined>)
}

export const stateTooltipContent = (name: string, state: string) => {
  let content = name
  if (state === 'not_invited') content += ' (not yet invited)'

  return content
}
